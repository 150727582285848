<template>
  <div class="table-responsive">
    <table
      class="
            table table-row-dashed table-row-gray-300
            align-middle
            gs-0
            gy-4
          "
    >
      <thead>
        <tr class="fw-bolder text-muted">
          <th class="min-w-50px">ID</th>
          <th class="min-w-100px">Name</th>
          <th class="min-w-120px text-end">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="branch in tableData" :key="branch.id">
          <td>
            <span class="text-dark fw-bolder text-hover-primary d-block fs-6">{{
              branch.id
            }}</span>
          </td>
          <td>
            <router-link
              :to="'/branches/' + branch.id"
              class="text-dark fw-bolder text-hover-primary fs-6"
              >{{ branch.name }}</router-link
            >
          </td>
          <td class="text-end">
            <div class="btn-group btn-group-sm pb-2 pt-1" role="group">
              <button
                href="#"
                type="button"
                class="btn btn-sm btn-warning"
                @click="editButtonClick(branch)"
                data-bs-toggle="modal"
                data-bs-target="#BranchModal"
              >
                Edit
              </button>
              <a
                type="button"
                class="btn btn-sm btn-danger"
                data-bs-toggle="modal"
                data-bs-target="#deleteModal"
                @click="deleteButtonClicked(branch)"
                >Delete</a
              >
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "BranchesDataTable",
  props: {
    tableData: Object
  },
  methods: {
    editButtonClick(branch) {
      this.$emit("editButtonClicked", branch);
    },
    deleteButtonClicked(branch) {
      this.$emit("deleteButtonClick", branch);
    }
  }
});
</script>
