<template>
  <div class="card">
    <BranchModal
        v-show="isThereAModalActive"
        :currentlySelectedBranch="currentlySelectedBranch"
        :title="modalSetup.title"
        :action="modalSetup.action"
        @saveChanges="saveDataFromModal"
    ></BranchModal>
    <DeleteModal
      v-show="isThereAModalActive"
      :currentlySelectedEntry="currentlySelectedBranch.name"
      @delete="deleteSelectedBranch"
    ></DeleteModal>
    <div class="card mb-5 mb-xl-8">
      <div class="card-header border-0 pt-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label fw-bolder fs-3 mb-1">Branches</span>

          <span class="text-muted mt-1 fw-bold fs-7"
          >Overview of added branches</span
          >
        </h3>
        <div
          class="ms-auto card-toolbar"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-trigger="hover"
          title="Click to add a new branch"
        >
          <a
            href="#"
            class="btn btn-sm btn-success"
            data-bs-toggle="modal"
            data-bs-target="#BranchModal"
            @click.prevent="openAddNewModal"
            ><span class="svg-icon svg-icon-3"
              ><svg
                fill="none"
                viewBox="0 0 24 24"
                height="24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  xmlns="http://www.w3.org/2000/svg"
                  opacity="0.5"
                  x="11.364"
                  y="20.364"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-90 11.364 20.364)"
                  fill="black"
                ></rect>
                <rect
                  xmlns="http://www.w3.org/2000/svg"
                  x="4.36396"
                  y="11.364"
                  width="16"
                  height="2"
                  rx="1"
                  fill="black"
                ></rect></svg
            ></span>
            New Branch
          </a>
        </div>
      </div>
      <div class="card-body py-3">
        <!--begin::Loading spinner-->
        <LoadingSpinner v-if="loading"></LoadingSpinner>
        <el-empty
            v-else-if="branchesList.length == 0"
            :description="
          'OOPS... Unfortunately no branches were found.'
        "
        ></el-empty>
        <!--end::Loading spinner-->
        <BranchesDataTable
          @editButtonClicked="openEditModal"
          @deleteButtonClick="openDeleteModal"
          v-else
          :tableData="branchesList"
        ></BranchesDataTable>
      </div>
      <Pagination
        :currentPage="pagination.current_page"
        :totalPages="pagination.last_page"
        @changeCurrentPage="changeSearchByPage"
      ></Pagination>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import BranchesDataTable from "@/components/system/Tables/BranchesDataTable.vue";
import Pagination from "@/components/pagination/Pagination.vue";
import BranchModal from "@/components/system/Modals/BranchModal.vue";
import DeleteModal from "@/components/general/DeleteModal.vue";

export default defineComponent({
  name: "BranchesIndex",
  components: {
    BranchesDataTable,
    BranchModal,
    LoadingSpinner,
    Pagination,
    DeleteModal
  },
  data() {
    return {
      pagination: {},
      isThereAModalActive: false,
      modalSetup: {},
      currentlySelectedBranch: {
        id: null,
        name: null
      }
    };
  },
  methods: {
    ...mapActions("BranchesModule", [
      "fetchData",
      "setPageNumber",
      "resetPageNumber",
      "destroyData",
      "update",
      "createNew",
      "resetState"
    ]),
    changeSearchByPage(pageNumber) {
      this.setPageNumber(pageNumber);
      this.fetchData();
    },
    openEditModal(branch) {
      this.modalSetup.title = "Edit Branch";
      this.modalSetup.action = "edit";
      this.currentlySelectedBranch = branch;
    },
    openAddNewModal() {
      this.resetBranchData();
      this.modalSetup.title = "";
      this.$nextTick(() => this.modalSetup.title = "Add new Branch");
      this.modalSetup.action = "addnew";
      this.isThereAModalActive = true;
    },
    openDeleteModal(entry) {
      this.currentlySelectedBranch = entry;
      this.isThereAModalActive = true;
    },
    deleteSelectedBranch() {
      this.destroyData(this.currentlySelectedBranch.id);
      this.isThereAModalActive = false;
      this.currentlySelectedBranch.id = null;
    },
    saveDataFromModal(dataFromModal) {
      if (dataFromModal.action == "edit") {
        this.update({
          values: dataFromModal.payload,
          id: this.currentlySelectedBranch.id
        });
      } else {
        this.createNew(dataFromModal.payload);
      }
    },
    resetBranchData() {
      this.currentlySelectedBranch = {
        id: null,
        name: null
      };
    },
  },
  computed: {
    ...mapGetters("BranchesModule", [
      "branchesList",
      "loading",
      "getPaginationData"
    ])
  },
  created() {
    this.fetchData();
  },
  updated() {
    this.pagination = this.getPaginationData;
  },
  unmounted() {
    this.resetState();
  }
});
</script>