<template>
  <!--begin::Modal-->
  <div
    class="modal fade"
    ref="BranchModal"
    id="BranchModal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <div class="modal-content">
        <Form
          class="form"
          ref="BranchModalForm"
          id="BranchModal_form"
          :validation-schema="validationSchema"
          @submit="submit"
        >
          <div class="modal-header" id="BranchModal_header">
            <h2>{{ title }}</h2>
            <div
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <span class="svg-icon svg-icon-1">
                <inline-svg src="media/icons/duotone/Navigation/Close.svg" />
              </span>
            </div>
          </div>
          <div class="modal-body py-10 px-lg-17">
            <div class="row mb-5">
              <div class="col-md-12 fv-row">
                <label class="required fs-5 fw-bold mb-2">Name</label>
                <Field
                    type="text"
                    class="form-control form-control-solid"
                    placeholder="Enter name"
                    name="name"
                    v-model.trim="branchData.name"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="name" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="mb-3 mt-3 col-12">
                <label for="countriesDropdown" class="form-label">Country</label>
                <Field v-slot="{ field }" name="country_id">
                  <CountriesDropdown
                      id="countriesDropdown"
                      v-bind="field"
                      v-model="branchData.country_id"
                  ></CountriesDropdown>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="country_id" />
                    </div>
                  </div>
                </Field>
              </div>
            </div>
            <div class="row">
              <div class="mb-3 mt-3 col-12">
                <label for="countriesDropdown" class="form-label">Currency</label>
                <Field v-slot="{ field }" name="currency_id">
                  <CurrenciesDropdown
                      id="countriesDropdown"
                      v-bind="field"
                      v-model="branchData.currency_id"
                  ></CurrenciesDropdown>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="currency_id" />
                    </div>
                  </div>
                </Field>
              </div>
            </div>
          </div>
          <div class="modal-footer flex-center">
            <button
              type="reset"
              id="BranchModal_cancel"
              class="btn btn-white me-3"
              data-bs-dismiss="modal"
            >
              Discard
            </button>
            <button
              :disabled="branchData.name == ''"
              type="submit"
              id="BranchModal_submit"
              class="btn btn-primary"
            >
              <span class="indicator-label"> Submit </span>
              <span class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>
<script lang="js">
import { defineComponent } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import CountriesDropdown from "@/components/dropdown/CountriesDropdown";
import { Modal } from 'bootstrap';
import CurrenciesDropdown from "@/components/dropdown/CurrenciesDropdown.vue";

function initialBranchData() {
  return {
        name: "",
    // eslint-disable-next-line @typescript-eslint/camelcase
        country_id: "",
    // eslint-disable-next-line @typescript-eslint/camelcase
    currency_id: ""
}};

export default defineComponent({
  name: "BranchModal",
  components: {
    CurrenciesDropdown,
    ErrorMessage,
    Field,
    Form,
    CountriesDropdown
  },
  props: {
    currentlySelectedBranch: Object,
    action : String,
    title : String
  },
  mounted() {
    this.modal = new Modal(this.$refs.BranchModal)
  },
  data() {
    return {
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required()
        .label("Name"),
      // eslint-disable-next-line @typescript-eslint/camelcase
      country_id: Yup.string()
        .required()
        .label("Country"),
      // eslint-disable-next-line @typescript-eslint/camelcase
      currency_id: Yup.string()
        .required()
        .label("Currency")
    }),
      branchData: initialBranchData(),
      modal: null
    };
  },
  methods: {
    submit() {
      this.validationSchema.isValid(this.branchData).then((validationResult) => {
        if(validationResult){
          this.modal.hide();
          // eslint-disable-next-line @typescript-eslint/camelcase
          this.$emit("saveChanges", {payload : this.branchData, action : this.action});
        }
      })
    },
  },
  watch: {
    currentlySelectedBranch() {
      if(this.action == "edit" ){
        this.branchData.name = this.currentlySelectedBranch.name
          // eslint-disable-next-line @typescript-eslint/camelcase
        this.branchData.country_id = this.currentlySelectedBranch.country_id;
          // eslint-disable-next-line @typescript-eslint/camelcase
        this.branchData.currency_id = this.currentlySelectedBranch.currency_id;
        Object.keys(this.branchData).forEach((key) => {
          this.$refs.BranchModalForm.setFieldValue(key, this.branchData[key]);
        });
      }
    },
    title() {
      if(this.action !== "edit") this.branchData = initialBranchData();
      this.$refs.BranchModalForm.resetForm(null);
    }
 }
});
</script>
