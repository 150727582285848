<template>
  <!--begin:branchesIndex-->
  <div class="row gy-5 g-xl-8">
    <BranchesIndex widget-classes="card-xxl-stretch mb-5 mb-xl-8"></BranchesIndex>
  </div>
  <!--end::branchesIndex-->
</template>

<script lang="js">
import { defineComponent, onMounted } from "vue";
import BranchesIndex from "@/components/system/BranchesIndex.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "BranchesView",
  components: {
    BranchesIndex
  },
  mounted() {
    setCurrentPageTitle("Branches");
  }
});
</script>
